<div class="section-container">
  <h2>Course: {{ course.description }}</h2>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Title:</h4>
    <h5>{{ course.title }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Location:</h4>
    <h5>{{ course.location }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Presenter:</h4>
    <h5>{{ course.presenter }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Date:</h4>
    <h5>{{ course.lectureDate | date }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4># CE Hours:</h4>
    <h5>{{ course.numCeHours }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Hours:</h4>
    <h5>{{ course.hours }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Doctors Price:</h4>
    <h5>{{ course.doctorPrice | currency }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Staff Price:</h4>
    <h5>{{ course.staffPrice | currency }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h4>Seats Remaining:</h4>
    <h5>{{ course.seatsRemaining }}</h5>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h5>{{ course.additionalNotes }}</h5>
  </div>
  <div *ngIf="!isRegistered">    
    <h2>Course Registration</h2>
    <div style="height: 40px;" *ngIf="course.seatsRemaining === 0">
      <mat-label
        ><p class="required">
          Sorry this course is sold out
        </p></mat-label
      >
    </div>
    <form [formGroup]="registrationForm" *ngIf="course.seatsRemaining > 0">
      <div fxLayout="column" fxLayoutGap="10px">
        <div [formArrayName]="attendeeFormsProp" *ngFor="let a of getRegistrationFormsControls(); index as index">
          <div [formGroupName]="index">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
              <mat-label fxLayout="row"
                >Attendee Type
                <p class="required">*</p>
                <p class="required" *ngIf="submitted && formArray.controls[index].controls.attendeeType.status === 'INVALID'">required</p></mat-label
              >
              <div *ngIf="index > 0" fxLayout="row" fxLayoutAlign="start center">
                <button (click)="removePerson(index)" class="incrementor-button remove-attendee" type="button">-</button
                ><mat-label (click)="removePerson(index)" class="button-hand"> Remove Attendee</mat-label>
              </div>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-xs="30px">
              <mat-radio-group fxLayout="row" fxLayoutGap="10px" formControlName="attendeeType">
                <mat-radio-button (click)="setCost(index, 'doctor')" value="doctor">Doctor</mat-radio-button>
                <mat-radio-button (click)="setCost(index, 'staff')" value="staff">Staff</mat-radio-button>
              </mat-radio-group>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
                <mat-label fxLayout="row"
                  >First Name
                  <p class="required">*</p>
                  <p class="required" *ngIf="submitted && formArray.controls[index].controls.firstName.status === 'INVALID'">required</p></mat-label
                >
                <input matInput formControlName="firstName" />
              </mat-form-field>
              <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
                <mat-label fxLayout="row"
                  >Last Name
                  <p class="required">*</p>
                  <p class="required" *ngIf="submitted && formArray.controls[index].controls.lastName.status === 'INVALID'">required</p></mat-label
                >
                <input matInput formControlName="lastName" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
              <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
                <mat-label fxLayout="row"
                  >Phone Number
                  <p class="required" *ngIf="index === 0">*</p>
                  <p class="required" *ngIf="submitted && formArray.controls[index].controls.phoneNumber.status === 'INVALID'"> {{ registrationForm.get('phoneNumber').value != '' ? 'Please enter a valid phone number' : 'required' }}</p></mat-label
                >
                <input matInput formControlName="phoneNumber" type="text" mask="(000) 000-0000" placeholder="(000) 000-0000" />
              </mat-form-field>
              <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
                <mat-label fxLayout="row"
                  >Email
                  <p class="required" *ngIf="index === 0">*</p>
                  <p class="required" *ngIf="submitted && formArray.controls[index].controls.email.status === 'INVALID'">
                    {{ registrationForm.get('email').value != '' ? 'Please enter a valid email' : 'required' }}
                  </p></mat-label
                >
                <input matInput formControlName="email" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isSoldOut">
          <button (click)="addPerson()" class="incrementor-button" type="button">+</button><mat-label (click)="addPerson()" class="button-hand"> Add Attendee</mat-label>
        </div>
        <mat-label><p class="required" *ngIf="isSoldOut">Sorry there are no more seats available. You cannot add another attendee</p></mat-label>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <h3>Total:</h3>
          <div class="retainer-total">{{ getTotal() | currency }}</div>
        </div>
        <div fxLayoutAlign="start start" fxLayout="column">
          <button mat-raised-button class="bracespace-button" type="button" (click)="checkIsSoldOut()">
            REGISTER
          </button>
          <div style="height: 40px;">
            <mat-label><p class="required" *ngIf="submitted && registrationForm.status === 'INVALID'">Please enter the missing fields</p></mat-label>
          </div>
          <div style="height: 40px;" *ngIf="isShortSeats">
            <mat-label
              ><p class="required">
                {{ course.seatsRemaining === 0 ? 'Sorry this course is sold out' : course.seatsRemaining > 1? 'Sorry there are only ' + course.seatsRemaining + ' seats available for this course.': 'Sorry there is only 1 seat available for this course.' }}
              </p></mat-label
            >
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" [fxShow]="showPaypal && registrationForm.status === 'VALID'">
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isRegistered" class="confirmed-container">
    <div class="section-divider"></div>
    <h2>Confirmed Attendees</h2>
    <table mat-table [dataSource]="confirmedAttendees">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        <td mat-footer-cell *matFooterCellDef class="table-label"></td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="lectureAttendeeType">
        <th mat-header-cell *matHeaderCellDef>Attendee Type</th>
        <td mat-cell *matCellDef="let element">{{ element.lectureAttendeeType }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phoneNumber }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="table-total">Total Cost:</td>
      </ng-container>

      <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef>Cost</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cost | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="table-total-amount">{{ getPurchaseTotal() | currency }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="table-footer"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
      </tr>
    </table>
  </div>
</div>
