import { Component } from '@angular/core';


@Component({
  selector: 'hor-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'the-bracespace';

  constructor() {
  }

  ngOnInit() {
   
  }

}
