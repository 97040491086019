import { Component, ChangeDetectionStrategy, SimpleChanges, OnChanges, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { MessageSetting, LoadingSignalSetting, MessageToShow, VideoCallSetting } from '@hor/data-access-shared/states/global-events/global-events.state';
import { AlertComponent } from '../alert/alert.component';
import { AlertModel, AlertType } from '@hor/data-access-shared/models/alert.model';
import { GlobalEventsActions } from '@hor/data-access-shared/states/global-events/global-events.action';
import { Constant } from '@hor/data-access-shared/constants/constant';

@Component({
  selector: 'hor-global-events',
  templateUrl: './global-events.component.html',
  styleUrls: ['./global-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalEventsComponent implements OnChanges {

  @Input() showBusyLoading: boolean;
  @Input() busyLoadingSetting: LoadingSignalSetting;
  @Input() messagesToShow: MessageToShow;
  @Input() videoCallSetting: VideoCallSetting;

  constructor(private snackbar: MatSnackBar, private store: Store) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

     if (changes.messagesToShow && changes.messagesToShow.currentValue) {
      // Show snackbar
      this.displayMessage();
    }
    else if (changes.showBusyLoading && changes.showBusyLoading.currentValue) {
      // Move busy loading logic here
      const model = <LoadingSignalSetting>changes.busyLoadingSetting.currentValue;
      if (model && model.mode) {

      }
    }
  }


  displayMessage() {
    // console.log(this.messagesToShow, this.messagesToShow.message);

    if (!this.messagesToShow.isShowMessage) {
      return;
    }

    const model = this.messagesToShow.message;
    // console.log(model);
    if (model && model.title) {

      console.log('show snackbar');

      const snackBarRef = this.snackbar.openFromComponent(AlertComponent, {
        data: <AlertModel>{
          title: model.title,
          message: model.msgShort,
          details: model.msgLong,
        },
        panelClass: model.type,
        duration: model.timeout,
      });

      snackBarRef.afterDismissed().subscribe(() => {
        this.store.dispatch(new GlobalEventsActions.HideMessage());
      });
    }
  }

}
