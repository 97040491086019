import moment from 'moment';

let currentYear = new Date().getFullYear();
const years = [];

for (let i = 0; i < 100; i++) {
  years.push(currentYear);
  currentYear--;
}

export class Constant {
  static enableConsoleDebug = true;

  static enable_posts = true;

  static uploadImage_maxSize = 3 * 1048576; // bytes = 3M
  static uploadVideo_maxSize = 20 * 1048576; // bytes = 20M

  static nav_bandmates = 'bandmates';
  static nav_login = 'login';
  static nav_firstLogin = '/login/first';
  static nav_editProfile = '/homes-dashboard/dashboard/editprofile';
  static bandmate_subnav_search = 'bandmates/search';
  static bandmate_subnav_add = 'bandmates/add';
  static bandmate_subnav_requests = 'bandmates/requests';
  static bandmate_subnav_join = 'bandmates/business/join';
  static business_subnav_search = 'bandmates/business/search';
  static business_subnav_add = 'bandmates/business/add';

  0;
  static business_subnav_posts = 'business/posts';
  static business_subnav_post_create = 'business/post/create';
  static business_subnav_post_edit = 'business/post/edit';

  static default_img = '/assets/img/hotels/no-image.jpg';
  static nav_facechat = 'facechat';
  static facechat_subnav_session = 'facechat/session';
  static facechat_subnav_newGroup = 'facechat/new';
  static facechat_subnav_video = 'facechat/video';
  static facechat_subnav_acceptChat = 'facechat/acceptChat';

  static defaultImageUrl = 'assets/homes-dashboard/images/user_default_img.png';
  static defaultMediaImageUrl = 'assets/mocks/concert_0.png';
  static defaultMediaBusinessImageUrl = 'assets/img/business/default.png';
  static defaultGroupChatUrl = 'assets/img/facechat/star.jpg';

  static monthsShort = moment.monthsShort().map((month, i) => ({ display: month, value: i + 1 }));
  static months31 = [1, 3, 5, 7, 8, 10, 12];
  static months30 = [4, 6, 9, 11];
  static birthYears = years;
  static days = [...Array(31).keys()].map((i) => i + 1);
  static inputMasks = {
    phone: '(000) 000-0000',
  };

  static bandmate_posts_pagesize = 5;
  static bandmate_news_pagesize = 5;
  static myBandmates_pagesize = 12;
  static bandmate_searchResult_pagesize = 6;

  static wowMachineSmall = 50;
  static wowMachineMedium = 150;
  static wowMachineBig = 250;

  static ticketMasterConcertSearchEndPoint = 'https://app.ticketmaster.com/discovery/v2/events.json';
  static ticketMasterConcertSearchApiKey = 'LLpWvhuMJRcLNqUu8kynLLUAeefyps4h';

  static defaultSnackbarDuration = 5000;

  static nav_vacation_package = 'vacation-package';
  static vacation_search_flight = 'vacation-package/search/flight';
  static vacation_search_hotel = 'vacation-package/search/hotel';
  static vacation_search_hotel_detail = 'vacation-package/search/hotel/detail';
  static vacation_search_car = 'vacation-package/search/car';
  static vacation_book = 'vacation-package/book';
  static vacation_book_complete = 'vacation-package/book/complete';

  static post_allowed_video_types = ['mp4', 'avi', 'wmv', 'mov'];
  static post_allowed_image_types = ['jpeg', 'jpg', 'png', 'gif', 'webp'];

  static mediaKinds = ['audioinput', 'audiooutput', 'videoinput'];
  static audioInputKind = 'audioinput';
  static audioOutputKind = 'audiooutput';
  static videoInputKind = 'videoinput';
  static videoInputTrackName = 'camera';
  static audioInputTrackName = 'microphone';

  static videoChatWaitingTime = 30; // seconds
}

export enum RelationShipStatus {
  Definitely = 'Definitely',
  Maybe = 'Maybe',
  None = 'None',
  Null = '',
}

export enum Permission {
  accelerometer = 'accelerometer',
  ambientLightSensor = 'ambient-light-sensor',
  backgroundFetch = 'background-fetch',
  backgroundSync = 'background-sync',
  bluetooth = 'bluetooth',
  camera = 'camera',
  displayCapture = 'display-capture',
  geolocation = 'geolocation',
  gyroscope = 'gyroscope',
  magnetometer = 'magnetometer',
  microphone = 'microphone',
  midi = 'midi',
  nfc = 'nfc',
  notifications = 'notifications',
  persistentStorage = 'persistent-storage',
  push = 'push',
  screenWakeLock = 'screen-wake-lock',
  speakerSelection = 'speaker-selection',
}

export enum MediaPermissionStatus {
  granted = 'granted',
  denied = 'denied',
  prompt = 'prompt',
  error = '',
}
