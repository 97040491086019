import { Injectable } from '@angular/core';
import { ApiOrderConfirmation, Attendee, Course } from '@hor/data-access-shared/models/thebracespace.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AuthService } from '../../auth/auth.service';
import { GetUserService } from '../../auth/get-user.service';
import { AuthActions } from './auth.action';

export interface AuthStateModel {
  authenticated: boolean; //need flag so profile is lazy fetched
  isLoading: boolean;
  course: Course;
  attendees: Array<Attendee>;
  isRegistered: boolean;
  isOrderConfirmed: boolean;
  orderConfirmation: ApiOrderConfirmation;
  saving: boolean;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    authenticated: false,
    isLoading: true,
    course: undefined,
    attendees: undefined,
    isRegistered: false,
    isOrderConfirmed: false,
    orderConfirmation: undefined,
    saving: false
  },
})
@Injectable()
export class AuthState {
  constructor(private getUserService: GetUserService, private authService: AuthService, private store: Store) {}

  @Selector()
  public static getIsLoading({ isLoading }: AuthStateModel) {
    return isLoading;
  }

  @Selector()
  public static getCourse({ course }: AuthStateModel) {
    return course;
  }

  @Selector()
  public static getAttendees({ attendees }: AuthStateModel) {
    return attendees;
  }

  @Selector()
  public static getIsSaving({ saving }: AuthStateModel) {
    return saving;
  }

  @Selector()
  public static getOrderConfirmation({ orderConfirmation }: AuthStateModel) {
    return orderConfirmation;
  }

  @Selector()
  public static getIsOrderConfirmed({ isOrderConfirmed }: AuthStateModel) {
    return isOrderConfirmed;
  }

  @Selector()
  public static getIsRegistered({ isRegistered }: AuthStateModel) {
    return isRegistered;
  }

  @Action(AuthActions.SignOut)
  signOut({ patchState, dispatch }: StateContext<AuthStateModel>, {}: AuthActions.SignOut) {
    localStorage.clear();
    sessionStorage.clear();
    /*
    We clear the storage on all tabs by setting a new item, if we depend on the clear to trigger
    it can cause an infinite loop of calls accross tabs/browsers
    */
    localStorage.setItem('hor-logout', Date.now().toString());
    localStorage.removeItem('hor-logout');
    this.authService.setUserData(null);
    dispatch(new AuthActions.Authenticated(false));
  }

  @Action(AuthActions.IsLoading)
  signIn({ patchState, dispatch }: StateContext<AuthStateModel>, action: AuthActions.IsLoading) {
    patchState({ isLoading: action.isLoading });
  }

  @Action(AuthActions.SetCourse)
  setCourse({ patchState, dispatch }: StateContext<AuthStateModel>, action: AuthActions.SetCourse) {
    patchState({ course: action.course });
    dispatch(new AuthActions.IsLoading(false));
  }

  @Action(AuthActions.SetAttendees)
  setAttendees({ patchState, dispatch }: StateContext<AuthStateModel>, action: AuthActions.SetAttendees) {
    patchState({ attendees: action.attendees, isRegistered: true });
  }

  @Action(AuthActions.ConfirmOrder)
  confirmOrder({ patchState, dispatch }: StateContext<AuthStateModel>, action: AuthActions.ConfirmOrder) {
    patchState({ orderConfirmation: action.order, isOrderConfirmed: true });
  }

  @Action(AuthActions.IsSaving)
  resetRetainerPage({ patchState, dispatch }: StateContext<AuthStateModel>, action: AuthActions.IsSaving) {
    patchState({ saving: action.isSaving });
  }
}
