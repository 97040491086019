export const HOR_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMMM DD, YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
    