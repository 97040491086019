import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationContainerComponent } from '@hor/ui-shared/registration-container/registration-container.component';
import { RetainersContainerComponent } from '@hor/ui-shared/retainers-container/retainers-container.component';


const routes: Routes = [
  {
    path:'learntogive',
    component: RegistrationContainerComponent
  },
  {
    path:'retainers',
    component: RetainersContainerComponent
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {        
          path: '',
          component: RegistrationContainerComponent,         
        },
        ...routes,
      ],
      { initialNavigation: 'enabled' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
