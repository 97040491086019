<div class="footer" [ngClass.lt-sm]="'footer-mobile'" fxLayout="row" fxLayout.lt-md="column">
    <div fxLayout="column" class="footer-section">
      <h1>Bedford</h1>
      <div>
        <p>620 Nine Mile Drive</p>
        <p>Suite 201</p>
        <p>Bedford, NS</p>
      </div>
      <br />
      <div>
        <p><a class="action-link" href="tel:(902)455-7222">Phone: (902) 455-7222</a></p>
        <p>Fax: (902) 455-7221</p>
        <p><a class="action-link" href="mailto:info@thebracespacebedford.com">info&#64;thebracespacebedford.com</a></p>
      </div>
    </div>
    <div fxLayout="column" class="footer-section">
      <h1>Dartmouth</h1>
      <div>
        <p>599 Portland St</p>
        <p>Suite B</p>
        <p>Dartmouth, NS</p>
      </div>
      <br />
      <div>
        <p><a class="action-link" href="tel:(902)435-3723">Phone: (902) 435-3723</a></p>
        <p>Fax: (902) 435-7737</p>
        <p><a class="action-link" href="mailto:info@thebracespacedartmouth.com">info&#64;thebracespacedartmouth.com</a></p>
      </div>
    </div>

    <div fxLayout="column" class="footer-section">
      <h1>Halifax</h1>
      <div>
        <p>6155 North St</p>
        <p>Suite 409</p>
        <p>Halifax, NS</p>
      </div>
      <br />
      <div>
        <p><a class="action-link" href="tel:(902)706-7022">Phone: (902) 706-7022</a></p>
        <p>Fax: (902) 536-6996</p>
        <p><a class="action-link" href="mailto:info@thebracespacehalifax.com">info&#64;thebracespacehalifax.com</a></p>
      </div>
    </div>

    <div fxLayout="column" class="footer-section">
      <h1>Sackville</h1>
      <div>
        <p>405 Sackville Dr</p>
        <p>Suite 103</p>
        <p>Lower Sackville, NS</p>
      </div>
      <br />
      <div>
        <p><a class="action-link" href="tel:(902)252-7222">Phone: (902) 252-7222</a></p>
        <p>Fax: (902) 455-7221</p>
        <p><a class="action-link" href="mailto:info@thebracespacesackville.com">info&#64;thebracespacesackville.com</a></p>
      </div>
    </div>
  </div>