import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'hor-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  timedOutCloser;
  hamburgerItems = ['about', 'patients', 'treatment', 'invisalign', 'blog']
  constructor() { }

  ngOnInit(): void {
  }

  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }

  hideShowElement(name:string){        
    document.getElementById(name).style.display === 'block'? document.getElementById(name).style.display='none': document.getElementById(name).style.display='block';
    if(name != "overlay" && name != "mobile-hamburger-overlay"){
      const itemsToHide = this.hamburgerItems.filter((x)=> x != name);
      itemsToHide.map((x)=>document.getElementById(x).style.display='none')
    }
  }

  navigateTo= (url:string)=> window.location.href = `https://www.thebracespace.com/${url}`;


}
