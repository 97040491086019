import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ApiInteceptorService } from './auth/api-inteceptor.service';

import { HighlightPipe } from './pipes/highlight.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { AuthState } from './states/auth/auth.state';
import { GlobalEventsState } from './states/global-events/global-events.state';
import { HttpStatusState } from './states/http-status/http-status.state';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forFeature([
      AuthState,
      HttpStatusState,
      GlobalEventsState,
    ]),
  ],
  exports: [HighlightPipe, LimitToPipe],
  declarations: [HighlightPipe, LimitToPipe],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInteceptorService,
      multi: true,
    },
  ],
})
export class DataAccessSharedModule {}
