import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LoginService } from '../registration-container/login.service';
import { Select, Store } from '@ngxs/store';
import { AuthActions } from '@hor/data-access-shared/states/auth/auth.action';
import { AuthState } from '@hor/data-access-shared/states/auth/auth.state';
import { Observable } from 'rxjs';
import { ApiOrderConfirmation } from '@hor/data-access-shared/models/thebracespace.model';

@Component({
  selector: 'hor-retainers-container',
  templateUrl: './retainers-container.component.html',
  styleUrls: ['./retainers-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetainersContainerComponent implements OnInit {

  constructor(private http: HttpClient, private loginService: LoginService, private store: Store,) { }
  @Select(AuthState.getIsOrderConfirmed)
  isOrdered$: Observable<boolean>;

  @Select(AuthState.getIsSaving)
  isSaving$: Observable<boolean>;

  
  @Select(AuthState.getOrderConfirmation)
  order$: Observable<Array<ApiOrderConfirmation>>;
  
  ngOnInit(): void {
    this.loginService.login().subscribe(
      (data: any) => {
        sessionStorage.setItem('hor-token', 'Bearer ' + data.token);
        localStorage.setItem('hor-login', Date.now().toString());
        localStorage.removeItem('hor-login');              
      },
      (error: Response) => {
        console.log('error', error);  
      }
    );
  }

}
