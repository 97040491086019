<hor-http-status-container></hor-http-status-container>
<hor-global-events-container></hor-global-events-container>
<ng-container *ngIf="siteLayout$ | async as siteLayout">
  <section class="main-section">
    <div
      [ngClass]="{
        container: showContainer(siteLayout)
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </section>
