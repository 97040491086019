import { ApiProfileModel } from '@hor/data-access-shared/models/api.profile.model';
import { ApiOrderConfirmation, Attendee, Course } from '@hor/data-access-shared/models/thebracespace.model';

export namespace AuthActions {
  export class Authenticated {
    static readonly type = 'Auth Authenticated';
    constructor(public authenticated: boolean) {}
  }

  export class SignOut {
    static readonly type = 'Auth Sign Out';
  }

  export class IsLoading {
    static readonly type = 'Auth Is Loading';
    constructor(public isLoading: boolean) {}
  }

  export class SetCourse {
    static readonly type = 'Auth Set Course';
    constructor(public course: Course) {}
  }

  export class SetAttendees {
    static readonly type = 'Auth Set Attendees';
    constructor(public attendees: Array<Attendee>) {}
  }

  export class ConfirmOrder {
    static readonly type = 'Auth Set Order';
    constructor(public order: ApiOrderConfirmation) {}
  }

  export class IsSaving {
    static readonly type = 'Auth Saving';
    constructor(public isSaving: boolean) {}
  }
}
