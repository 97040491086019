<ng-container *ngIf="showBusyLoading">
  <div class="overlay">
      <div class="center">
        <mat-progress-spinner
          [diameter]="busyLoadingSetting.diameter"
          [mode]="busyLoadingSetting.mode"
          [color]="busyLoadingSetting.color"
          [strokeWidth]="busyLoadingSetting.strokeWidth"
          [value]="busyLoadingSetting.value">
        </mat-progress-spinner>
      </div>
  </div>
</ng-container>
