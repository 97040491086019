import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'hor-incrementor',
  templateUrl: './incrementor.component.html',
  styleUrls: ['./incrementor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncrementorComponent implements OnInit {
  @Output() updateTotal = new EventEmitter<void>();
  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() amount = 0;
  @Input() max = 4;
  @Input() min = 0;
  constructor() {}

  ngOnInit(): void {}

  add() {
    if (this.amount < this.max) {
      this.amount++;
      this.control.setValue(this.amount);   
      this.updateTotal.emit();   
    }
  }

  remove() {
    if (this.amount > this.min) {
      this.amount--;
      this.control.setValue(this.amount);
      this.updateTotal.emit();
    }
  }
}
