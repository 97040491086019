import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@hor/app-config';
import moment from 'moment';
import { of } from 'rxjs/internal/observable/of';
import { Environment } from '../interfaces/environment';
import { ApiProfileModel } from '../models/api.profile.model';

@Injectable({
  providedIn: 'root',
})
export class GetUserService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: Environment
  ) {}

  getUser() {
    if (sessionStorage.getItem('hor-token')) {
      return this.http.get(this.appConfig.secureApiUrl + 'api/users/me');
    }
    return of();
  }
  getUserPreferences() {
    if (sessionStorage.getItem('hor-token')) {
      return this.http.get(this.appConfig.secureApiUrl + 'api/preferences');
    }
    return of();
  }

  getUserInfo(uuid: string) {
    const endpoint = `${this.appConfig.secureApiUrl}api/user-infos/${uuid}`;
    return this.http.get(endpoint);
  }

  setUserHostPreference(mode) {
    return this.http.put(
      this.appConfig.secureApiUrl + 'api/user-session/access-mode/' + mode,
      null
    );
  }

  updateUserInfo(data: ApiProfileModel) {
    if (data.dob && !moment(data.dob, 'YYYY-MM-DD').isValid()) {
      // perform a data format conversion
      data.dob = moment(data.dob, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }
    return this.http.put(this.appConfig.secureApiUrl + 'api/user-infos', data);
  }

  deleteUserInfo(uuid: string) {
    return this.http.delete(`${this.appConfig.secureApiUrl}api/users/${uuid}`);
  }

  sendEmailInvite(email: string){
    return this.http.post(`${this.appConfig.secureApiUrl}api/invite/${email}`, null);
  }

  redeemEmailInvite(email: string){
    return this.http.post(`${this.appConfig.secureApiUrl}api/invite/redeem/${email}`, null);
  }

  verifyInvite(email: string, code: string){
    const postData = {
      code: code,
      toEmail: email
    }
    return this.http.post(`${this.appConfig.secureApiUrl}api/invite/verify`, postData);
  }

  getNotifications(isHost, size, page, unread?: boolean) {
    let Params = new HttpParams();
    Params = Params.append('size', size);
    Params = Params.append('page', page);
    Params = Params.append('sort', 'createdDate,desc');

    if (unread !== undefined) {
      Params = Params.append('unread', unread ? 'true' : 'false');
    }

    let mode = 'host';
    if (!isHost) mode = 'guest';

    return this.http.get(
      this.appConfig.secureApiUrl + 'api/' + mode + '/notifications',
      {
        headers: new HttpHeaders().set('HOR-SHOWLOADING', 'false'),
        params: Params,
      }
    );
  }

  /*
  getNotification(isHost) {
    if (isHost) {
      return this.http.get(this.appConfig.legacyApiUrl + "api/host/notifications");
    } else {
      return this.http.get(this.appConfig.legacyApiUrl + "api/guest/notifications");
    }
  }
  */

  notificationRead(id) {
    return this.http.put(
      this.appConfig.secureApiUrl + 'api/notifications/' + id + '/read',
      {}
    );
  }
  deleteNotification(id) {
    return this.http.delete(
      this.appConfig.secureApiUrl + 'api/notifications/' + id,
      {}
    );
  }
}
