import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AlertModel,
  AlertType,
} from '@hor/data-access-shared/models/alert.model';

import { AlertComponent } from '../alert/alert.component';
import { HttpErrorModel } from '@hor/data-access-shared/states/http-status/http-status.state';
import { Store } from '@ngxs/store';
import { HttpStatusActions } from '@hor/data-access-shared/states/http-status/http-status.action';

@Component({
  selector: 'hor-http-status',
  templateUrl: './http-status.component.html',
  styleUrls: ['./http-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpStatusComponent implements OnChanges {
  constructor(private snackbar: MatSnackBar, private store: Store) {}
  @Input() error: HttpErrorModel;

  ngOnChanges({ error }: SimpleChanges) {
    const model = <HttpErrorModel>error.currentValue;
    if (model && model.message) {
      const snackBarRef = this.snackbar.openFromComponent(AlertComponent, {
        data: <AlertModel>{
          message: model.message,
          details: [],
        },
        panelClass: AlertType.Error,
        duration: 5000,
      });

      snackBarRef.afterDismissed().subscribe(() => {
        this.store.dispatch(new HttpStatusActions.ClearErrors());
      });
    }
  }
}
