import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    const limit = args[0] || 300;
    const append = args[1] || '';

    if (value.length > limit) {
      return value.substring(0, limit) + append;
    }

    return value;
  }
}
