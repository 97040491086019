<div fxLayoutAlign="space-between center">
  <div fxLayout="column">
    <h3>{{ data.message }}</h3>
    <ul *ngIf="data.details.length > 0">
      <li *ngFor="let detail of data.details">{{ detail }}</li>
    </ul>
  </div>
  <div>
    <button mat-button (click)="snackBarRef.dismiss()">
      DISMISS
    </button>
  </div>
</div>
