import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { LoaderState } from './loader-state';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private callCounter: number = 0;
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() {}
  show() {
    this.callCounter++;
    //console.log("##### show" + this.callCounter);
    if (this.callCounter === 1)
      //show on first call
      this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    this.callCounter--;
    //console.log("##### hide" + this.callCounter);
    if (this.callCounter === 0) {
      //hide on last call
      //console.log("##### hidding" + this.callCounter);
      this.loaderSubject.next(<LoaderState>{ show: false });
    }
  }
}
