import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

import { AgmCoreModule } from '@agm/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  DataAccessSharedModule,
} from '@hor/data-access-shared';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { NgxMaskModule } from 'ngx-mask';
import { SwiperModule } from 'ngx-swiper-wrapper';


import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { AlertComponent } from './alert/alert.component';
import { HttpStatusContainerComponent } from './http-status-container/http-status-container.component';
import { HttpStatusComponent } from './http-status/http-status.component';
import { GlobalEventsContainerComponent } from './global-events-container/global-events-container.component';
import { GlobalEventsComponent } from './global-events/global-events.component';

import { HOR_DATE_FORMATS } from './date-format.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { IncrementorComponent } from './incrementor/incrementor.component';
import { MaterialModule } from './material.module';
import { RegistrationContainerComponent } from './registration-container/registration-container.component';
import { RegistrationComponent } from './registration/registration.component';
import { RetainersContainerComponent } from './retainers-container/retainers-container.component';
import { RetainersComponent } from './retainers/retainers.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { SiteLayoutComponent } from './site-layout/site-layout.component';

@NgModule({
  imports: [
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    DataAccessSharedModule,
    NgxCleaveDirectiveModule,
    Ng5SliderModule,
    SwiperModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    NgxPayPalModule,
    NgxMaskModule.forRoot(),
    NgxsFormPluginModule,
  ],
  exports: [
    NgxPayPalModule,
    MaterialModule,
    MatTableModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    SiteLayoutComponent,
    AgmCoreModule,
    NgxMaskModule,
    GlobalEventsComponent,
    GlobalEventsContainerComponent,
    DataAccessSharedModule,
    RegistrationContainerComponent,
    RetainersContainerComponent,

  ],
  declarations: [
    HttpStatusComponent,
    HttpStatusContainerComponent,
    SiteLayoutComponent,
    AlertComponent,
    GlobalEventsComponent,
    GlobalEventsContainerComponent,
    RetainersContainerComponent,
    RetainersComponent,
    RegistrationComponent,
    RegistrationContainerComponent,
    IncrementorComponent,
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: HOR_DATE_FORMATS },
  ],
})
export class UiSharedModule {}
