<div class="header" fxLayout="row" fxLayoutAlign="space-between" fxLayoutAlign.lt-md="space-around">
    <div fxLayout="row">
      <div class="header-label" [ngClass.lt-sm]="'header-label-mobile'">
        <div (click)="navigateTo('new-patients/book-consultation-request')">Book Free Consultation</div>
      </div>
      <div class="header-divider">|</div>
      <div class="header-label" [ngClass.lt-sm]="'header-label-mobile'">
        <div (click)="hideShowElement('overlay')">Call Now</div>
      </div>
    </div>
    <div [fxHide.gt-sm]="true" fxLayout="row">
      <div class="hamburger-container">
        <div class="hamburger" (click)="hideShowElement('mobile-hamburger-overlay')">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" [fxHide.lt-md]="true" fxLayoutAlign="end" fxLayoutGap="22px">
      <div class="top-level-menu">
        <div (click)="navigateTo('')">Home</div>
      </div>
      <div
        class="top-level-menu"
        [matMenuTriggerFor]="aboutUs"
        #aboutTrigger="matMenuTrigger"
        (mouseenter)="mouseEnter(aboutTrigger); mouseLeave(patientsTrigger); mouseLeave(treatmentTrigger); mouseLeave(invisalignTrigger)"
        (mouseleave)="mouseLeave(aboutTrigger)"
      >
        <div trigger>About Us</div>
      </div>

      <div
        class="top-level-menu"
        [matMenuTriggerFor]="newPatients"
        #patientsTrigger="matMenuTrigger"
        (mouseenter)="mouseEnter(patientsTrigger); mouseLeave(aboutTrigger); mouseLeave(treatmentTrigger); mouseLeave(invisalignTrigger)"
        (mouseleave)="mouseLeave(patientsTrigger)"
      >
        <div trigger>New Patients</div>
      </div>

      <div
        class="top-level-menu"
        [matMenuTriggerFor]="treatment"
        #treatmentTrigger="matMenuTrigger"
        (mouseenter)="mouseEnter(treatmentTrigger); mouseLeave(aboutTrigger); mouseLeave(patientsTrigger); mouseLeave(invisalignTrigger)"
        (mouseleave)="mouseLeave(treatmentTrigger)"
      >
        <div trigger>Treatment</div>
      </div>

      <div
        class="top-level-menu"
        [matMenuTriggerFor]="invisalign"
        #invisalignTrigger="matMenuTrigger"
        (mouseenter)="mouseEnter(invisalignTrigger); mouseLeave(aboutTrigger); mouseLeave(patientsTrigger); mouseLeave(treatmentTrigger)"
        (mouseleave)="mouseLeave(invisalignTrigger)"
      >
        <div fxLayout="row" trigger>
          Invisalign®
        </div>
      </div>

      <div class="top-level-menu">
        <div (click)="navigateTo('FAQ')">FAQ</div>
      </div>

      <div
        class="top-level-menu"
        [matMenuTriggerFor]="blog"
        #blogTrigger="matMenuTrigger"
        (mouseenter)="mouseEnter(blogTrigger); mouseLeave(invisalignTrigger); mouseLeave(aboutTrigger); mouseLeave(patientsTrigger); mouseLeave(treatmentTrigger)"
        (mouseleave)="mouseLeave(blogTrigger)"
      >
        <div trigger>Blog</div>
      </div>

      <div class="top-level-menu">
        <div (click)="navigateTo('contact-us')">Contact</div>
      </div>

       <div></div>      
    </div>
  </div>
  <div class="header-body-divider"></div>
  <mat-menu #aboutUs="matMenu" [hasBackdrop]="false" class="menu-class">
    <div
      (mouseenter)="mouseEnter(aboutTrigger); mouseLeave(patientsTrigger); mouseLeave(treatmentTrigger)"
      (mouseleave)="mouseLeave(aboutTrigger); mouseLeave(invisalignTrigger)"
    >
      <div mat-menu-item (click)="navigateTo('about-us/meet-our-doctors')">Meet Our Doctors</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('about-us/about-the-team')">About the Team</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('about-us/what-sets-us-apart')">What sets us apart</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('about-us/offce-tour')">Office Tour</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('about-us/testimonials')">Testimonials</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('about-us/modern-technology')">Modern Technology</div>
    </div>
  </mat-menu>

  <mat-menu #newPatients="matMenu" [hasBackdrop]="false" class="menu-class">
    <div
      (mouseenter)="mouseEnter(patientsTrigger); mouseLeave(aboutTrigger); mouseLeave(treatmentTrigger)"
      (mouseleave)="mouseLeave(patientsTrigger); mouseLeave(invisalignTrigger)"
    >
      <div mat-menu-item (click)="navigateTo('new-patients/book-consultation-request')">Book consultation request</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('new-patients/consultation-information')">Consultation information</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('new-patients/financial-information')">Financial information</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('new-patients/adults')">Adults</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('new-patients/teens')">Teens</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('new-patients/children')">Children</div>
    </div>
  </mat-menu>

  <mat-menu #treatment="matMenu" [hasBackdrop]="false" class="menu-class">
    <div
      (mouseenter)="mouseEnter(treatmentTrigger); mouseLeave(aboutTrigger); mouseLeave(patientsTrigger); mouseLeave(invisalignTrigger)"
      (mouseleave)="mouseLeave(treatmentTrigger)"
    >
      <div mat-menu-item (click)="navigateTo('treatment/types-of-braces')">Types of braces</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('treatment/clear-aligners')">Clear aligners</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('treatment/life-with-braces')">Life with braces</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('treatment/patient-login')">Patient Login</div>
    </div>
  </mat-menu>

  <mat-menu #invisalign="matMenu" [hasBackdrop]="false" class="menu-class">
    <div
      (mouseenter)="mouseEnter(invisalignTrigger); mouseLeave(treatmentTrigger); mouseLeave(aboutTrigger); mouseLeave(patientsTrigger)"
      (mouseleave)="mouseLeave(invisalignTrigger)"
    >
      <div mat-menu-item (click)="navigateTo('invisalign/diamond-invisalign-provider')">Diamond Provider</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('invisalign/about-invisalign')">About Invisalign®</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('invisalign/invisalign-faq')">Invisalign® FAQ</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('invisalign/invisalign-teen')">Invisalign Teen®</div>
    </div>
  </mat-menu>

  <mat-menu #blog="matMenu" [hasBackdrop]="false" class="menu-class">
    <div
      (mouseenter)="mouseEnter(blogTrigger); mouseLeave(invisalignTrigger); mouseLeave(treatmentTrigger); mouseLeave(aboutTrigger); mouseLeave(patientsTrigger)"
      (mouseleave)="mouseLeave(blogTrigger)"
    >
      <div mat-menu-item (click)="navigateTo('blog/are-you-looking-for-an-orthodontist-in-your-area')">Are you looking for an Orthodontist in your area?</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('blog/children-smile-at-the-brace-space')">Children Smile at The Brace Space</div>
      <div class="border"></div>
      <div mat-menu-item (click)="navigateTo('blog/children-with-new-braces-back-to-school')">Children With New Braces Back to School</div>
    </div>
  </mat-menu>

  <div id="overlay">
    <div style="height: 50px;"></div>
    <div class="call-us-container">
      <div class="call-us-options">
        <div (click)="hideShowElement('overlay')" class="overlay-close-button">X</div>
        <div>
          <div class="call-us-title">
            Call today for a consultation
          </div>
          <ul style="list-style: none; color: #1aa4a3;">
            <li class="call-us-number"><a href="tel:902.455.7222">(902) 455-7222 - Bedford </a></li>
            <li class="call-us-number"><a href="tel:902.435.3723">(902) 435-3723 - Dartmouth </a></li>
            <li class="call-us-number"><a href="tel:902.252.7222">(902) 252-7222 - Sackville</a></li>
            <li class="call-us-number"><a href="tel:902.706.7022">(902) 706-7022 - Halifax </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="mobile-hamburger-overlay">
    <div style="height: 50px;"></div>
    <div class="mobile-menu-container">
      <div (click)="hideShowElement('mobile-hamburger-overlay')" class="mobile-menu-close-button">X</div>
      <div class="hamburger-menu-container">
        <div class="hamburger-top-level-menu">
          <div (click)="navigateTo('')">Home</div>
        </div>
        <div class="hamburger-top-level-menu">
          <div (click)="hideShowElement('about')">About Us</div>
          <div id="about" class="hamburger-menu-items-container" style="display: none;">
            <div class="hamburger-menu-items" (click)="navigateTo('about-us/meet-our-doctors')">Meet Our Doctors</div>
            <div class="hamburger-menu-items" (click)="navigateTo('about-us/about-the-team')">About the Team</div>
            <div class="hamburger-menu-items" (click)="navigateTo('about-us/what-sets-us-apart')">What sets us apart</div>
            <div class="hamburger-menu-items" (click)="navigateTo('about-us/offce-tour')">Office Tour</div>
            <div class="hamburger-menu-items" (click)="navigateTo('about-us/testimonials')">Testimonials</div>
            <div class="hamburger-menu-items" (click)="navigateTo('about-us/modern-technology')">Modern Technology</div>
          </div>
        </div>

        <div class="hamburger-top-level-menu">
          <div (click)="hideShowElement('patients')">New Patients</div>
          <div id="patients" class="hamburger-menu-items-container" style="display: none;">
            <div class="hamburger-menu-items" (click)="navigateTo('new-patients/book-consultation-request')">Book consultation request</div>
            <div class="hamburger-menu-items" (click)="navigateTo('new-patients/consultation-information')">Consultation information</div>
            <div class="hamburger-menu-items" (click)="navigateTo('new-patients/financial-information')">Financial information</div>
            <div class="hamburger-menu-items" (click)="navigateTo('new-patients/adults')">Adults</div>
            <div class="hamburger-menu-items" (click)="navigateTo('new-patients/teens')">Teens</div>
            <div class="hamburger-menu-items" (click)="navigateTo('new-patients/children')">Children</div>
          </div>
        </div>

        <div class="hamburger-top-level-menu">
          <div (click)="hideShowElement('treatment')">Treatment</div>
          <div id="treatment" class="hamburger-menu-items-container" style="display: none;">
            <div class="hamburger-menu-items" (click)="navigateTo('treatment/types-of-braces')">Types of braces</div>
            <div class="hamburger-menu-items" (click)="navigateTo('treatment/clear-aligners')">Clear aligners</div>
            <div class="hamburger-menu-items" (click)="navigateTo('treatment/life-with-braces')">Life with braces</div>
            <div class="hamburger-menu-items" (click)="navigateTo('treatment/patient-login')">Patient Login</div>
          </div>
        </div>

        <div class="hamburger-top-level-menu">
          <div (click)="hideShowElement('invisalign')">Invisalign ®</div>
          <div id="invisalign" class="hamburger-menu-items-container" style="display: none;">
            <div class="hamburger-menu-items" (click)="navigateTo('invisalign/diamond-invisalign-provider')">Diamond Provider</div>
            <div class="hamburger-menu-items" (click)="navigateTo('invisalign/about-invisalign')">About Invisalign ®</div>
            <div class="hamburger-menu-items" (click)="navigateTo('invisalign/invisalign-faq')">Invisalign ® FAQ</div>
            <div class="hamburger-menu-items" (click)="navigateTo('invisalign/invisalign-teen')">Invisalign Teen ®</div>
          </div>
        </div>

        <div class="hamburger-top-level-menu">
          <div (click)="navigateTo('FAQ')">FAQ</div>
        </div>

        <div class="hamburger-top-level-menu">
          <div (click)="hideShowElement('blog')">Blog</div>
          <div id="blog" class="hamburger-menu-items-container" style="display: none;">
            <div class="hamburger-menu-items" (click)="navigateTo('blog/are-you-looking-for-an-orthodontist-in-your-area')">Are you looking for an Orthodontist in your area?</div>
            <div class="hamburger-menu-items" (click)="navigateTo('blog/children-smile-at-the-brace-space')">Children Smile at The Brace Space</div>
            <div class="hamburger-menu-items" (click)="navigateTo('blog/children-with-new-braces-back-to-school')">Children With New Braces Back to School</div>
          </div>
        </div>

        <div class="hamburger-top-level-menu">
          <div (click)="navigateTo('contact-us')">Contact</div>
        </div>

        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>