export class OfficesModel {
  name: string;
  province: string;
  city: string;
  address: string;
  address2: string;
  phone: string;
}

export class ShippingDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  location = new ShippingLocation();
  cost = 0;
}
export class ShippingLocation {
  address: string;
  city: string;
  country = 'CA';
  province: string;
  postalCode: string;
}

export class Item {
  name: string;
  cost: number;
  unitCost: number;
  qty: number;
}

export class PaypalOrder {
  id: string;
  createdTime: string;
  amount: string;
}

export class PaypalItem {
  name: string;
  quantity: string;
  category = 'DIGITAL_GOODS';
  unit_amount = new PaypalItemUnitAmount();
}

export class PaypalItemUnitAmount {
  currency_code = 'CAD';
  value: string;
}

export class ClientOrder {
  items = new Array<Item>();
  total: string;
  tax: string;
  paypalCreationTime: string;
  paypalId: string;
  deliveryMethod: string;
  pickupLocation = new OfficesModel();
  shippingInformation?: ShippingDetails;
}

export class Attendee {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  lectureAttendeeType: string;
  cost: string;
  ordinal: number;
}

export class Course {
  id: number;
  title: string;
  description: string;
  lectureDate: string;
  presenter: string;
  numCeHours: number;
  hours: string;
  seatsRemaining: number;
  doctorPrice: number;
  staffPrice: number;
  location: string;
  paypalUrl: string;
  additionalNotes: string;
}

export class RegistrationDetails {
  attendees = new Array<Attendee>();
  paypalId: string;
  paypalCreationTime: string;
  totalAmount: string;
}

export class ApiShippingInfo {
  address: ApiShippingAddress;
}

export class ApiShippingAddress {
  address_line_1: string;
  address_line_2: string;
  admin_area_2: string;
  admin_area_1: string;
  postal_code: string;
  country_code: string;
}

export class ApiOrderBreakdown {
  item_total: ApiOrderBreakdownDetail;
  shipping?: ApiOrderBreakdownDetail;
  tax_total?: ApiOrderBreakdownDetail;
}
export class ApiOrderBreakdownDetail {
  currency_code: string;
  value: string;
}

export class ApiPurchaseItem {
  name: string;
  description: string;
  sku: string;
  unit_amount: ApiPurchaseItemAmount;
  tax: ApiPurchaseItemAmount;
  quantity: string;
  category: string;
}

export class ApiPurchaseItemAmount {
  currency_code: string;
  value: string;
}

export class ApiOrderConfirmation {
  id :number;
  createdDate : string;
  totalBeforeTax : number;
  totalTax : number;
  totalAfterTax : number;
  shipperFirstName: string;
  shipperLastName: string;
  currencyCode : string;
  patientInformation : ApiPatientInformation
  shippingDetails: ApiShippingDetails;
  orderItemList : ApiOrderItem[];
}

export class ApiPatientInformation {
  id : number;
  orderId : number;
  createdDate : string;
  firstName : string;
  lastName : string;
  phoneNumber : string;
  dateOfBirth : string;
  emailAddress : string;
  requiresNewMold : boolean
}

export class ApiShippingDetails  {
  id : number;
  orderId : number;
  addressLine1 : string;
  addressLine2 : string;
  adminArea1 : string;
  adminArea2 : string;
  postalCode : string;
  countryCode : string;
}

export class ApiOrderItem  {
  id : number;
  orderId : number;
  category : string;
  unitAmount : number;
  unitCurrencyCode : string;
  quantity : number;
  itemName : string;
  description : string;
  sku : string;
  taxAmount : number;
  taxCurrencyCode : string;
}